
const NotFoundPage = () =>{
 
   return(
<div style={{
    width: "250px",
    height: "250px",
    padding: "20px",
    border: "1px solid #77aaff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
    backgroundColor: "lightgray",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center horizontally and vertically
    flexDirection: "column",
    overflow: "hidden" // For keeping the sparkles inside the box
}}>
    <span style={{ fontSize: "40px", color: "#77aaff", fontWeight: "bold" }}>404</span>
    <span style={{ fontSize: "16px", color: "#333", marginTop: "10px" }}>Sayfa Bulunamadı</span>

    {/* Sparkles will animate automatically */}
    <div className="sparkles" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <span className="sparkle" style={{ position: 'absolute' }}></span>
        <span className="sparkle" style={{ position: 'absolute' }}></span>
        <span className="sparkle" style={{ position: 'absolute' }}></span>
    </div>
    {/* Add the sparkle animation CSS */}
<style>
{`
  /* Sparkle keyframes */
  @keyframes sparkle {
    0% { opacity: 0; transform: scale(0) translate(0, 0); }
    50% { opacity: 1; transform: scale(1.2) translate(50px, 50px); }
    100% { opacity: 0; transform: scale(0) translate(100px, 100px); }
  }

  .sparkle {
    width: 5px;
    height: 5px;
    background-color: #77aaff;
    border-radius: 50%;
    animation: sparkle 1.5s linear infinite;
  }

  /* Add sparkle variations */
  .sparkles span:nth-child(1) {
    animation-delay: 0s;
    top: 20%;
    left: 10%;
  }

  .sparkles span:nth-child(2) {
    animation-delay: 0.5s;
    top: 50%;
    left: 50%;
  }

  .sparkles span:nth-child(3) {
    animation-delay: 1s;
    top: 70%;
    left: 80%;
  }
`}
</style>
</div>


   );

};


export default NotFoundPage;