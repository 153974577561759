// CompanyInfoCard.js
import React,{forwardRef, useEffect} from 'react';
import { Formik, Form,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TextField, Button,  Typography, Card, CardContent, Grid, InputAdornment } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import BadgeIcon from '@mui/icons-material/Badge';

const validationSchema = Yup.object({
  companyName: Yup.string().required('Company Name is required'),
  industry: Yup.string().required('Industry is required'),
  companySize: Yup.number()
    .positive('Must be a positive number')
    .required('Company Size is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  websiteUrl: Yup.string()
    .url('Invalid URL format')
    .required('Website URL is required'),
  companyIdNumber: Yup.string().required('Company Identification Number is required'),
});

const FormObserver = ({handleOnChange}) => {
    const { values } = useFormikContext();
    useEffect(() => {
        handleOnChange(values)
    }, [values,handleOnChange]);
    return null;
};

const CompanyInfoCard = forwardRef(({info,setCompanyInfo,submitMode=false},ref) => {
  console.log("info ",info)
  const initialValues = {
    companyName: info?.CompanyName || "" ,
    industry: info?.Industry||"",
    companySize:info?.CompanySize||0,
    companyAddress: info?.CompanyAddress||"",
    websiteUrl: info?.WebsiteUrl||"",
    companyIdNumber: info?.CompanyIdentificationNumber||"",
  };

  const onSubmit = (values) => {
    console.log('Form data', values);
    // Handle form submission (e.g., send to API)
    //setCompanyInfo(values)
  };
  const handleOnChange = (event) => {
    console.log("Form::onChange", event);
    setCompanyInfo(event);
    
};
  return (
    <Card sx={{ maxWidth: 630, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ marginBottom: 2, textAlign: 'center' }}>
          Company Information
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          innerRef={ref}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormObserver handleOnChange={handleOnChange}/>
              <Grid container spacing={2}>
                {/* Company Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Industry */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Industry"
                    name="industry"
                    value={values.industry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.industry && Boolean(errors.industry)}
                    helperText={touched.industry && errors.industry}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Company Size */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Size"
                    name="companySize"
                    type="number"
                    value={values.companySize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.companySize && Boolean(errors.companySize)}
                    helperText={touched.companySize && errors.companySize}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PeopleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Company Address */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Address"
                    name="companyAddress"
                    value={values.companyAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.companyAddress && Boolean(errors.companyAddress)}
                    helperText={touched.companyAddress && errors.companyAddress}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Website URL */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Website URL"
                    name="websiteUrl"
                    type="url"
                    value={values.websiteUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.websiteUrl && Boolean(errors.websiteUrl)}
                    helperText={touched.websiteUrl && errors.websiteUrl}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Company Identification Number */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Identification Number"
                    name="companyIdNumber"
                    value={values.companyIdNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.companyIdNumber && Boolean(errors.companyIdNumber)}
                    helperText={touched.companyIdNumber && errors.companyIdNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BadgeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Submit Button */}
                {submitMode?
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
                :<></>
               }
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
});

export default CompanyInfoCard;
