import React, { forwardRef,useEffect,useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../AuthContext';
// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #333;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
`;

const Suggestion = styled.div`
  font-size: 1.25rem;
  color: #007bff;
  margin-top: 1rem;
  font-weight: bold;
`;

const Feedback = styled.div`
  font-size: 1rem;
  color: ${({ isAvailable }) => (isAvailable ? '#28a745' : '#d9534f')};
  margin-top: 0.5rem;
  text-align: center;
`;

const ErrorMessage = styled.div`
  font-size: 0.9rem;
  color: #d9534f;
  margin-top: 0.5rem;
  text-align: center;
`;


const SubdomainSuggestion = forwardRef(({ setSubdomainName },ref) => {
  const [companyName, setCompanyName] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [error, setError] = useState('');

  const {post} = useAuth();
  
  const [isAvailable,setIsAvailable] = useState();
  const handleInputChange = (e) => {
    const name = e.target.value;
    setCompanyName(name);

    // Convert company name to a subdomain-friendly format
    const formattedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with dashes
      .replace(/^-+|-+$/g, ''); // Trim dashes from start and end

    // Limit the subdomain to a maximum of 25 characters
    const truncatedName = formattedName.slice(0, 25);

    setSubdomain(truncatedName);
    setSubdomainName(truncatedName);
    setError(''); // Clear error on input change
  };

  
  const checkSubdomainAvailability = () => {
     post("/checksubdomain/",{subdomain:subdomain}).then(res=>
      {
        setIsAvailable(subdomain&&res?.data?.status===true)

      })
  };
  
  useImperativeHandle(ref, () => ({
    GetDomianName(){
      return subdomain;
    },
    submitForm()
    {
    
        if(!isAvailable){
          setError("This Field is Required")
       }
      
    },
    errors:isAvailable?{}:{"Subdomain":"This Field is Required"}
  }));

  return (
    <Container>
      <Title>Subdomain Alın</Title>
      <Input
        type="text"
        placeholder="Enter company name"
        value={companyName}
        onChange={handleInputChange}
        required
        onBlur={checkSubdomainAvailability}
      />
      {subdomain && (
        <Suggestion>
          Önerilen Subdomain: {subdomain}.taskdone.com.tr
        </Suggestion>
      )}
      {companyName && (
        <Feedback isAvailable={isAvailable}>
          {isAvailable
            ? 'This subdomain is available!'
            : 'This subdomain is already taken. Please try another.'}
        </Feedback>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
});

export default SubdomainSuggestion;
