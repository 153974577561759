// LegalComplianceCard.js
import React,{forwardRef, useEffect} from 'react';
import { Formik, Form, Field,useFormikContext } from 'formik';
import * as Yup from 'yup';
import {  Button, Box, Typography, Card, CardContent, Grid, FormControlLabel, Checkbox } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';

const validationSchema = Yup.object({
  termsAgreement: Yup.bool().oneOf([true], 'You must agree to the terms and conditions.'),
  gdprConsent: Yup.bool().oneOf([true], 'You must consent to GDPR compliance.'),
});

const FormObserver = ({handleOnChange}) => {
  const { values } = useFormikContext();
  useEffect(() => {
      handleOnChange(values)
  }, [values,handleOnChange]);
  return null;
};

const LegalComplianceCard = forwardRef(({setLegalCompliance,submitMode=false},ref) => {
  const initialValues = {
    termsAgreement: false,
    gdprConsent: false,
  };

  const onSubmit = (values) => {
    console.log('Form data', values);
    // Handle form submission (e.g., send to API)
  };
  const handleOnChange = (event) => {
    console.log("Form::onChange", event);
    setLegalCompliance(event);
    
  };

  return (
    <Card sx={{ maxWidth: 630, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ marginBottom: 2, textAlign: 'center' }}>
          Legal and Compliance Information
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={ref}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormObserver handleOnChange={handleOnChange}/>
              <Grid container spacing={2}>
                {/* Agreement to Terms and Conditions */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        as={Checkbox}
                        name="termsAgreement"
                        color="primary"
                        checked={values.termsAgreement}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <GavelIcon sx={{ marginRight: 1 }} />
                        <Typography>
                          I agree to the{' '}
                          <a href="/terms" target="_blank" rel="noopener noreferrer">
                            Terms and Conditions
                          </a>.
                        </Typography>
                      </Box>
                    }
                  />
                  {touched.termsAgreement && Boolean(errors.termsAgreement) && (
                    <Typography color="error">{errors.termsAgreement}</Typography>
                  )}
                </Grid>

                {/* GDPR Consent */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        as={Checkbox}
                        name="gdprConsent"
                        color="primary"
                        checked={values.gdprConsent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <SecurityIcon sx={{ marginRight: 1 }} />
                        <Typography>
                          I consent to data processing in compliance with GDPR.
                        </Typography>
                      </Box>
                    }
                  />
                  {touched.gdprConsent && Boolean(errors.gdprConsent) && (
                    <Typography color="error">{errors.gdprConsent}</Typography>
                  )}
                </Grid>

                {/* Submit Button */}
                {submitMode?
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
                :<></>}
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
});

export default LegalComplianceCard;
