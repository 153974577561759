import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar,{BarBlock} from './navbar/Navbar.js';
import { BrowserRouter as Router, Route, Routes ,Navigate} from 'react-router-dom';
import Login from './screens/Login.js';
import Home from './screens/Home.js';
import Register from './screens/Register.js';
import { AuthProvider,useAuth } from './AuthContext.js';
import Product from './screens/Product.js';
import Payment from './screens/Payment.js';
import TermsAndConditions from './screens/TermsAndConditions.js';
import PaymentSuccess from './screens/Success.js';
import Dashboard from './screens/Dashboard.js';
import ManageData from './screens/ManageData.js';
import { Helmet } from 'react-helmet';
import NotFoundPage from './screens/404.js';

function Headers() {
  return (
      <Helmet>
        <title>TaskDone</title>
        <link rel="icon" href="./logo-black.jpg" />
        <meta name="description" content="Task management systems" />
        <meta name="keywords" content="TaskDone" />
        <meta charSet="utf-8" />
      </Helmet>
  );
}


function MainStack()
{
  const {user,loading} = useAuth();
  const vpnConfigUrl = '/path/to/vpn-config.ovpn';

  if (loading) {
    // Optionally show a loading indicator or nothing while checking authentication
    return <div>Loading...</div>;
  }


  return (
      <Router>
          <Headers/>
          <BarBlock/>
          <Navbar style = {{position:"fixed"}}/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/product/:product_id" element={user?<Product  />:<Navigate to="/login"/>} />
            <Route path="/payment" element={user?<Payment  />:<Navigate to="/login"/>} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path='/success' element={user?<PaymentSuccess/>:<Navigate to="/login"/>}/>
            <Route path='/dashboard' element={user?<Dashboard vpnConfigUrl={vpnConfigUrl}/>:<Navigate to="/login"/>}/>
            <Route path='/manage/data' element={user?<ManageData/>:<Navigate to="/login"/>}/>
            <Route path="/404" element={<NotFoundPage/> } />
            <Route path="*" element={<Navigate replace to="/404" />} />

          </Routes>
    </Router>
  )
}

function App() {

  return (
    <AuthProvider>
       <MainStack/>
   </AuthProvider>
  );
}

export default App;
