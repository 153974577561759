import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const url = `https://api.taskdone.com.tr`

const AuthContext = createContext();

axios.interceptors.request.use((config) => {
  // Exclude the Authorization header for specific URLs
  const excludedUrls = ['/banners/','/plans/','/ourcustomers/'];
  console.log(config.url)
  if (excludedUrls.some(url => config.url.includes(url))) {
      delete config.headers['Authorization']; // Only affects this request
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  //const [hasSubscription,setHasSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('authToken'));
    console.log(token)
    console.log("selam")
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token.access}`;
      axios.defaults.headers.common['Content-Type'] = `application/json`;
      axios.post(`${url}/api/token/verify/`,{token:token.access})
        .then(response => {
          console.log(response)
          setUser(true)})
        .catch((err) => {
          console.log("hata",err)
          localStorage.removeItem('authToken')})
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (phone_number, password) => {
    try {
      const response = await axios.post(`${url}/api/token/`, { phone_number, password });
      console.log(response.data)
      localStorage.setItem('authToken', JSON.stringify(response.data));
      setUser(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data}`;
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setUser(null);
    delete axios.defaults.headers.common['Authorization'];
  };

  const get = (path,config=undefined)=>{    
     return axios.get(`${url}${path}`,config)
  }

  const post = (path,config=undefined)=>{
    return axios.post(`${url}${path}`,config)
 }

  return (
    <AuthContext.Provider value={{ user,post, get,login, logout, loading,url }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);