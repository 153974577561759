import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { FaPhone, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; 

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 92vh;
  background: #f5f5f5;
`;

const FormWrapper = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
`;

const Input = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #333;
  outline: none;
  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
`;

const ErrorText = styled.div`
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #0056b3;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
`;

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\+?[0-9]+$/, "Phone number must be digits only")
    .min(10, 'Phone number must be at least 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

// LoginForm component
const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => setShowPassword(!showPassword);
  const { login } = useAuth(); // Destructure the login function from the auth context
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleSubmit = ({ phone, password }) => {
    setError(''); // Reset any previous errors
     login(phone, password).then(()=>{
      navigate('/'); 
     }).catch(error=>{
      console.log(error)
      setError('Failed to log in. Please check your credentials.')}) 

  };

  return (
    <Container>
      <FormWrapper>
        <Title>Login</Title>
        <Formik
          initialValues={{
            phone: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="phone">Phone Number</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="phone"
                    type="tel"
                    placeholder="1234567890"
                    className={errors.phone && touched.phone ? 'is-invalid' : ''}
                  />
                  <IconWrapper>
                    <FaPhone />
                  </IconWrapper>
                </div>
                <ErrorMessage name="phone" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="********"
                    className={errors.password && touched.password ? 'is-invalid' : ''}
                  />
                  <IconWrapper onClick={handlePasswordToggle}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </IconWrapper>
                </div>
                <ErrorMessage name="password" component={ErrorText} />
              </FormGroup>

              <Button type="submit">Login</Button>
              {error && <ErrorText>{error}</ErrorText>}
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default LoginForm;
