import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp, FaDownload } from 'react-icons/fa';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f4f6f8;
  min-height: 92vh;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
`;

const TitleLink = styled.a`
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Section = styled.div`
  margin-bottom: 1rem;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  cursor: pointer;
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d6d8db;
  }
`;

const SectionContent = styled.div`
  padding: 1rem;
  border-top: 1px solid #eee;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;

  & > span {
    font-weight: bold;
    color: #555;
  }

  & > div {
    color: #333;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  height: 20px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background-color: #28a745;
  transition: width 0.4s ease;
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  & > svg {
    margin-left: 8px;
  }
`;

// Dashboard Component
const Dashboard = ({ vpnConfigUrl }) => {
  const [openSections, setOpenSections] = useState({
    companyDetails: false,
    paymentDetails: false,
    credentials: false,
    vpnConfig: false,
    accountSettings: false,
    information: false,
  });
  
  const [subscription,setSubscription] = useState();
  const [progressPercentage,setProgressPercentage] = useState();
  const {get} = useAuth();
  const navigate = useNavigate();
  useEffect(()=>{
         
        get("/dashboard/").then(res=>{
          if(Object.keys(res.data).length===0)
          {
             navigate("/") 
          }
          setSubscription(res.data)
          console.log("data",res.data)
        })
  },[get,navigate])

  useEffect(()=>{
        const daysBetweenPayments = Math.ceil(
          (new Date(subscription?.NextPaymentTime) - new Date(subscription?.PaymentTime)) /
            (1000 * 60 * 60 * 24)
        );
        console.log(daysBetweenPayments)
        const daysSinceLastPayment = Math.ceil(
          (new Date() - new Date(subscription?.PaymentTime)) / (1000 * 60 * 60 * 24)
        );
        console.log(daysSinceLastPayment)
        setProgressPercentage(Math.min(
          (daysSinceLastPayment / daysBetweenPayments) * 100,
          100
        ))
  },[subscription])

  // Toggle section open/close
  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  const downloadVpnConfig = ()=>{
    get("/vpnconfig/",{ responseType: 'blob'}).then(response=>{
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${subscription?.subdomain?.subdomain}_wireguard.conf`; // Set the file name and extension
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    })
  }

  return (
    <Container>
      <Card>
        <TitleLink
          href={`https://taskdone.com.tr/@${subscription?.subdomain?.subdomain}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          taskdone.com.tr/@{subscription?.subdomain?.subdomain}
        </TitleLink>
        <Section>
          <SectionHeader onClick={() => toggleSection('paymentDetails')}>
            <span>Payment Details</span>
            {openSections.paymentDetails ? <FaChevronUp /> : <FaChevronDown />}
          </SectionHeader>
          <SectionContent isOpen={openSections?.paymentDetails}>
            <InfoRow>
              <span>Last Payment Time:</span>
              <div>{new Date(subscription?.PaymentTime).toLocaleDateString()}</div>
            </InfoRow>
            <InfoRow>
              <span>Next Payment Time:</span>
              <div>{new Date(subscription?.NextPaymentTime).toLocaleDateString()}</div>
            </InfoRow>
            <ProgressBar>
              <Progress percentage={progressPercentage} />
            </ProgressBar>
          </SectionContent>
        </Section>

        <Section>
          <SectionHeader onClick={() => toggleSection('credentials')}>
            <span>Credentials</span>
            {openSections.credentials ? <FaChevronUp /> : <FaChevronDown />}
          </SectionHeader>
          <SectionContent isOpen={openSections.credentials}>
            <InfoRow>
              <span>Subdomain Password:</span>
              <div>{subscription?.server?.default_password}</div>
            </InfoRow>
            <InfoRow>
              <span>Phone Number:</span>
              <div>{subscription?.account?.phone_number}</div>
            </InfoRow>
          </SectionContent>
        </Section>
       {/* 
        <Section>
          <SectionHeader onClick={() => toggleSection('vpnConfig')}>
            <span>VPN Configuration</span>
            {openSections.vpnConfig ? <FaChevronUp /> : <FaChevronDown />}
          </SectionHeader>
          <SectionContent isOpen={openSections.vpnConfig}>
            <DownloadButton onClick={downloadVpnConfig} download>
              Download VPN Config <FaDownload />
            </DownloadButton>
          </SectionContent>
        </Section>
        */}
      </Card>
    </Container>
  );
};

export default Dashboard;
