import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f6f8;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: #333;
  margin-top: 1rem;
`;

const Message = styled.p`
  font-size: 1rem;
  color: #666;
  margin-top: 0.5rem;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  color: #28a745;
  font-size: 4rem !important;
`;

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to dashboard after 5 seconds
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 3000);

    // Cleanup the timer if the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Container>
      <Card>
        <SuccessIcon />
        <Title>Payment Successful!</Title>
        <Message>Your transaction was completed successfully.</Message>
        <Message>You will be redirected to your dashboard shortly.</Message>
      </Card>
    </Container>
  );
};

export default PaymentSuccess;
