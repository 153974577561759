// OptionalInfoCard.js
import React,{useEffect} from 'react';
import { Formik, Form ,useFormikContext} from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Typography, Card, CardContent, Grid, InputAdornment, MenuItem } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import SourceIcon from '@mui/icons-material/Source';

const validationSchema = Yup.object({
  referralSource: Yup.string().optional(),
  additionalNotes: Yup.string().optional(),
});


const FormObserver = ({handleOnChange}) => {
  const { values } = useFormikContext();
  useEffect(() => {
      handleOnChange(values)
  }, [values,handleOnChange]);
  return null;
};


const OptionalInfoCard = ({setOptionalInfo,onlyComments=false,submitMode=false}) => {
  const initialValues = {
    referralSource: '',
    additionalNotes: '',
  };

  const onSubmit = (values) => {
    console.log('Form data', values);
    // Handle form submission (e.g., send to API)
  };

  const handleOnChange = (event) => {
    console.log("Form::onChange", event);
    setOptionalInfo(event);
    
  };  

  return (
    <Card sx={{ maxWidth: 630, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ marginBottom: 2, textAlign: 'center' }}>
          Optional Information
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormObserver handleOnChange={handleOnChange}/>
              <Grid container spacing={2}>
                {/* Referral Source */}
                {!onlyComments?
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Referral Source"
                    name="referralSource"
                    value={values.referralSource}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.referralSource && Boolean(errors.referralSource)}
                    helperText={touched.referralSource && errors.referralSource}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SourceIcon />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Referral">Referral</MenuItem>
                    <MenuItem value="Marketing Campaign">Marketing Campaign</MenuItem>
                    <MenuItem value="Social Media">Social Media</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>
                </Grid>:<></>}

                {/* Additional Notes or Comments */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Additional Notes or Comments"
                    name="additionalNotes"
                    value={values.additionalNotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.additionalNotes && Boolean(errors.additionalNotes)}
                    helperText={touched.additionalNotes && errors.additionalNotes}
                    multiline
                    rows={3}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CommentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Submit Button */}
                {submitMode?
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
                    :<></>}
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default OptionalInfoCard;
