import React,{useState,useEffect} from 'react';
import './OurCustomers.css'; // Import the CSS for styling
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import '../i18n.js';


function OurCustomers() {
  const {get,url} = useAuth();
  const [customersData,setOurCustomers] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(()=>{
      get("/ourcustomers/").then(res=>{
          setOurCustomers(res.data)
      })
  },[get]);
  return (
    <section className="OurCustomers">
      <h2 className="section-title">{t("Our Customers")}</h2>

      <div className="customer-container">

        { customersData.map((customer,index)=>{
            return(
              <div key={index} className="customer-item">
                <a href={customer.link} target="_blank" rel="noreferrer"><img src={`${url}${customer.logo}`} alt={customer.name} /></a>  
            </div>
            )
        })

        }
        {/* Add more customer items as needed */}
      </div>

    </section>
  );
}

export default OurCustomers;
