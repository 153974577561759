import BannerSlider from '../components/BannerSlider';
import Footer from '../navbar/Footer.js';
import PricingSection from '../components/PricingSection';
import OurCustomers from '../components/OurCustomers.js';
import AppLinks from '../components/AppLinks';
import { useTranslation } from 'react-i18next';
//import TrialPlan from '../components/TrialPlan.js';
import FAQSection from '../components/FAQ.js';

function Home() {
    const { t } = useTranslation();
    return (
    <>
        <main className="Content">
          <BannerSlider/>
          <section className="main-content">
            <h1>{t("Welcome to TaskDone")}</h1>
            {/*<p>{t("Manage your tasks efficiently with our professional solutions.")}</p>
                Add your main content here */}
            <PricingSection/>
            <AppLinks />
            <FAQSection/>
            <OurCustomers/>
          </section>
        </main>
        <Footer/>
    </>
    );
}

export default Home;
