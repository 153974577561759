import React,{useState,useEffect,useLayoutEffect} from 'react';
import { useAuth } from '../AuthContext';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Gerekli modüller artık böyle import ediliyor
import { Autoplay } from "swiper/modules";


function BannerSlider() {


  const {get,url} = useAuth();
  const [Banners,setBanners] = useState([]);
  const [isMobile,setIsMobile] = useState(window.innerWidth < 810); 
  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < 810)
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  useEffect(()=>{
      get("/banners/").then(res=>{
        console.log("banners : ",res.data)
        setBanners(res.data)
      })
  },[get])

  return (
    <div style={{ width: "98vw",height:"420px", overflow: "hidden",marginBottom:"10px"}}>
    <Swiper
      modules={[Autoplay]}
      pagination={false}
      autoplay={{
        delay: 3000, // Delay between slides in milliseconds (3000ms = 3 seconds)
        disableOnInteraction: false, // Allows autoplay to continue after user interaction
      }}
      navigation={false} 
      style={{ width: "100%",height: "auto",maxHeight:"400px" }}
    >
      {Banners.map((banner, index) => (
        <SwiperSlide style={{width:"100%",display:"flex",alignContent:"center",justifyContent:"center"}} key={index}>
          <img
            src={`${url}${isMobile ? banner.mobileImage : banner.image}`}
            alt={`Banner ${index + 1}`}
            style={isMobile ?{aspectRatio:"1/1",width:"98vw",height:"auto"}:{margin:"auto auto",aspectRatio:"2",width: "900px", height:"400px" }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  );
}

export default BannerSlider;
