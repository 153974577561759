import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="Footer">
      <div className="footer-container">
        <div className="footer-section company-info">
          <h4>Hakkımızda</h4>
          <p>TaskDone, profesyonel görev yönetimi çözümlerinin önde gelen sağlayıcısıdır. Misyonumuz, ekiplerin işbirliği yapmasına ve işleri verimli bir şekilde yapmasına yardımcı olmaktır.</p>
        </div>
        <div className="footer-section contact-info">
          <h4>Bize Ulaşın</h4>
          <p>Email: karakockaan326@gmail.com</p>
          {/*<p>Tel: +1 (555) 123-4567</p>*/}
          <p>Adres: Turkey,Konya</p>
        </div>
        <div className="footer-section quick-links">
          <h4>Hızlı Bağlantılar</h4>
          <ul>
            <li><a href="/">Ana Sayfa</a></li>
            <li><a href="/">Servisler</a></li>
            <li><a href="/">Ücretlendirmeler</a></li>
            <li><a href="/">İletişim</a></li>
            <li><a href="/">Gizlilik Politikası</a></li>
          </ul>
        </div>
        <div className="footer-section social-media">
          <h4>Bizi takip edin</h4>
          <div className="social-icons">
            <a href="/" aria-label="Facebook"><FaFacebook /></a>
            <a href="/" aria-label="Twitter"><FaTwitter /></a>
            <a href="https://www.linkedin.com/in/kaan-karako%C3%A7-0038a7287/" target="_blank" rel="noreferrer" aria-label="LinkedIn"><FaLinkedin /></a>
            <a href="https://www.instagram.com/kaankarakoc42/" target="_blank" rel="noreferrer" aria-label="Instagram"><FaInstagram /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 TaskDone. Tüm hakları saklıdır.</p>
      </div>
    </footer>
  );
}

export default Footer;
