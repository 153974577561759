// TermsAndConditions.js
import React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4, padding: 2 }}>
      <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          <em>Last updated: [Date]</em>
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to [Company Name]! These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with [Company Name] ("us", "we", or "our") and our website [website URL] (the "Service"). By accessing or using the Service, you agree to comply with and be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          2. Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          3. Services
        </Typography>
        <Typography variant="body1" paragraph>
          [Briefly describe the services you provide, including any restrictions or limitations.]
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          4. Payment Terms
        </Typography>
        <Typography variant="body1" paragraph>
          [If applicable, describe your payment terms, including billing cycles, payment methods, and any penalties for late payments.]
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          5. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          The Service and its original content, features, and functionality are and will remain the exclusive property of [Company Name] and its licensors.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          6. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
        </Typography>
        <Typography variant="body1" paragraph>
          Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          7. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall [Company Name], nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          8. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms shall be governed and construed in accordance with the laws of [State/Country], without regard to its conflict of law provisions.
        </Typography>
        <Typography variant="body1" paragraph>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          9. Changes
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.
        </Typography>
        <Typography variant="body1" paragraph>
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          10. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us:
        </Typography>
        <Typography variant="body1" paragraph>
          Email: <Link href="mailto:contact@example.com">contact@example.com</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          Address: [Company Address]
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
