// Navbar.js
import React, { useState } from 'react';
import { FaBars, FaTimes, FaCaretDown } from 'react-icons/fa';
import './Navbar.css';
import Logo from '../logo-black.jpg';
import { useAuth } from '../AuthContext'; 

export function BarBlock() {
  return (
    <div style={{ height: "8vh" }}></div>
  );
}



function Navbar({ style }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const profileImageUrl = 'https://via.placeholder.com/40'; // Replace with actual profile image URL
  const companyLogoUrl = Logo; // Replace with actual logo URL

  const handleMenuToggle = () => setMenuOpen(!menuOpen);
  const handleDropdownToggle = () => setDropdownOpen(!dropdownOpen);
  const { user,logout } = useAuth();
  console.log(user)
  return (
    <nav className="Navbar" style={style}>
      <div className="navbar-container">
        <div className="LeftSection">
          <a href='/'>
             <img src={companyLogoUrl} alt="Company Logo" className="company-logo" />
          </a>
          <h3 className="brand-name">TaskDone</h3>
        </div>
        <div className="RightSection">
          <div className="profile-container" onClick={handleDropdownToggle}>
            <img src={profileImageUrl} alt="Profile" className="profile-photo" />
            <FaCaretDown className="dropdown-icon" />
            {dropdownOpen && (
                <div className="dropdown-menu">
                  {user ? (
                    <>
                    <a href='/dashboard' className="dropdown-link">Dashboard</a>
                    <a href='/manage/data' className="dropdown-link">Manage Data</a>
                    <div onClick={logout} className="dropdown-link">Logout</div>
                    </>
                  ) : (
                    <>
                      <a href="/login" className="dropdown-link">Login</a>
                      <a href="/register" className="dropdown-link">Register</a>
                    </>
                  )}
                </div>
            )}
          </div>
        </div>
        <div className="menu-icon" onClick={handleMenuToggle}>
          {menuOpen ? <></> : <FaBars />}
        </div>
      </div>

      {/* Side Drawer */}
      <div className={`side-drawer ${menuOpen ? 'open' : ''}`}>
        <div className="side-drawer-header">
          <img src={companyLogoUrl} alt="Company Logo" className="drawer-company-logo" />
          <FaTimes className="close-drawer-icon" onClick={handleMenuToggle} />
        </div>
        <div className="side-drawer-content">
          <div className="drawer-profile">
            <img src={profileImageUrl} alt="Profile" className="drawer-profile-photo" />
          </div>
          {user ? (
                    <>
                    <a href='/dashboard' className="drawer-link">Dashboard</a>
                    <a href='/manage/data' className="drawer-link">Manage Data</a>
                    <div onClick={logout} className="drawer-link">Logout</div>
                    </>
                  ) : (
                    <>
                      <a href="/login" className="drawer-link">Login</a>
                      <a href="/register" className="drawer-link">Register</a>
                    </>
                  )
          }
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
