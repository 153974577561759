import React, { useState } from 'react';
import './FAQsection.css'; // Ensure you create this CSS file for styles

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Ücretsiz deneme süresi ne kadar sürüyor?",
      answer: "Ücretsiz deneme süresi 1 aydır ve herhangi bir ödeme bilgisi gerektirmez.",
    },
    {
      question: "Premium planı nasıl yükseltebilirim?",
      answer: "Premium plana yükseltmek için, hesabınıza giriş yapın ve 'Planınızı Güncelleyin' bölümünden yeni planınızı seçin.",
    },
    {
      question: "Destek ekibine nasıl ulaşabilirim?",
      answer: "Destek ekibimize e-posta göndererek veya web sitemizdeki canlı sohbet özelliğini kullanarak ulaşabilirsiniz.",
    },
    {
      question: "Aboneliğimi nasıl iptal edebilirim?",
      answer: "Aboneliğinizi iptal etmek için, hesabınıza giriş yapın ve 'Abonelikler' sekmesine gidin. Buradan iptal işlemini gerçekleştirebilirsiniz.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <h2 className="faq-title">Sıkça Sorulan Sorular</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleToggle(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span className="toggle-icon">
                {activeIndex === index ? '−' : '+'}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
