import React from 'react';
import { useAuth } from '../AuthContext';
import {  Button,  Card, CardContent, Grid} from '@mui/material';
// Styled components


const UpdateMyDataButton = ({ values ,refs}) => {
  const {post} = useAuth();
  const handleUpdate = () => {
    post("/update/data/",{values:values}).then(res=>{
      console.log(res.data)
    })
  };

  return (
    <Card sx={{ maxWidth: 630, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent >
      <Grid item xs={12}>
                  <Button
                    onClick={()=>{
                      Promise.all(refs.map(ref => 
                          ref.current.submitForm()
                      )).then(()=>{
                        if(refs.map(ref=>Object.keys(ref.current.errors).length===0).every(v => v === true))
                        {
                          handleUpdate();
                        }
                      });
                      //handlePaymentRedirect();
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Update My Data
                  </Button>
                </Grid>
      </CardContent>
    </Card>
    
    
  );
};

export default UpdateMyDataButton;
