import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


function Payment() {
    const {state} = useLocation();
    console.log(state);

    useEffect(() => {
        const loadScript = async () => {
          try {
            // Fetch the script content
            // Extract JavaScript code from within the <script> tags
              // Create a new script element
            const script = document.createElement('script');
            script.className = "payment";
            script.type = 'text/javascript';
            script.text = state.form;
    
              // Append the script to the head
            document.head.appendChild(script);
            
 
          } catch (error) {
            console.error('Failed to load script:', error);
          }
        };
    
        loadScript();
      }, [state]);

    return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div class="tab-pane fade active in" id="desc">
            <div class="row">
            <div id="iyzipay-checkout-form" class="responsive"></div>
            </div>
        </div>

   
    </div>
    );
}

export default Payment