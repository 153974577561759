// src/components/RegisterForm.js
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { FaUser, FaEnvelope, FaPhone, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from '../AuthContext'; 
import { useNavigate } from 'react-router-dom';
// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
  background: #f5f5f5;
`;

const FormWrapper = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
`;

const Input = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #333;
  outline: none;
  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
`;

const ErrorText = styled.div`
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #0056b3;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
`;

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be at least 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

// RegisterForm component
const RegisterForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handlePasswordToggle = () => setShowPassword(!showPassword);
  const { post } = useAuth();
  const handleSubmit = (values) => {
     post("/register/",values).then(res=>{
      console.log(res)
      if(res.status){
         navigate('/login');
      } 
     }).catch(error=>{
      console.log(error)
     }) 
  };

  return (
    <Container>
      <FormWrapper>
        <Title>Register</Title>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            password: '',
            confirm_password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>

              <FormGroup>
                <Label htmlFor="first_name">First Name</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="first_name"
                    type="text"
                    placeholder="John"
                    className={errors.first_name && touched.first_name ? 'is-invalid' : ''}
                  />
                  <IconWrapper>
                    <FaUser />
                  </IconWrapper>
                </div>
                <ErrorMessage name="first_name" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="last_name">Last Name</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="last_name"
                    type="text"
                    placeholder="Doe"
                    className={errors.last_name && touched.last_name ? 'is-invalid' : ''}
                  />
                  <IconWrapper>
                    <FaUser />
                  </IconWrapper>
                </div>
                <ErrorMessage name="last_name" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="email"
                    type="email"
                    placeholder="john.doe@example.com"
                    className={errors.email && touched.email ? 'is-invalid' : ''}
                  />
                  <IconWrapper>
                    <FaEnvelope />
                  </IconWrapper>
                </div>
                <ErrorMessage name="email" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="phone_number">Phone Number</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="phone_number"
                    type="text"
                    placeholder="1234567890"
                    className={errors.phone_number && touched.phone_number ? 'is-invalid' : ''}
                  />
                  <IconWrapper>
                    <FaPhone />
                  </IconWrapper>
                </div>
                <ErrorMessage name="phone_number" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="********"
                    className={errors.password && touched.password ? 'is-invalid' : ''}
                  />
                  <IconWrapper onClick={handlePasswordToggle}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </IconWrapper>
                </div>
                <ErrorMessage name="password" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="confirm_password">Confirm Password</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    name="confirm_password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="********"
                    className={errors.confirm_password && touched.confirm_password ? 'is-invalid' : ''}
                  />
                  <IconWrapper onClick={handlePasswordToggle}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </IconWrapper>
                </div>
                <ErrorMessage name="confirm_password" component={ErrorText} />
              </FormGroup>

              <Button type="submit">Register</Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default RegisterForm;
