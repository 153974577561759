import "./Product.css"
import Footer from '../navbar/Footer';
import CompanyInfoCard from '../components/CompanyInfoCard';
import BillingInfoCard from '../components/BillingInfoCard';
import OptionalInfoCard from '../components/OptionalInfoCard';
import { useEffect, useRef, useState } from 'react';
import UpdateMyDataButton from '../components/UpdateMyDataButton';
import { useAuth } from '../AuthContext';

function ManageData(props) {
    const [CompanyInfo,setCompanyInfo] = useState({});
    const [BillingInfo,setBillingInfo] = useState({});
    const [OptionalInfo,setOptionalInfo] = useState({});

    const BillingInfoRef = useRef(null);
    const CompanyInfoRef = useRef(null);

    const {get} = useAuth();
    const [userData,setUserData] = useState({});
    useEffect(()=>{
        console.log("fetching datas")
        get("/user/data/").then(res=>{
            setUserData(res.data)
            console.log("res :",res.data)
        })
    },[get])

    return (
    <>
    <div className='main'>
            <CompanyInfoCard info={userData?.CompanyInfo}  ref={CompanyInfoRef} setCompanyInfo={setCompanyInfo}/>
            <BillingInfoCard info={userData?.BillingInfo} ref={BillingInfoRef} setBillingInfo={setBillingInfo}/>
            <OptionalInfoCard onlyComments={true} setOptionalInfo={setOptionalInfo}/>
            <UpdateMyDataButton refs={[CompanyInfoRef,BillingInfoRef]}  values={{
                CompanyInfo:CompanyInfo,
                BillingInfo:BillingInfo,
                OptionalInfo:OptionalInfo,
            }}/>
    </div>
    <Footer/>
    </>
    );
}

export default ManageData;