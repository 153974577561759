import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import {  Button,  Card, CardContent, Grid} from '@mui/material';
// Styled components


const ForwardPaymentButton = ({ values ,refs}) => {
  const {post} = useAuth();
  const navigate = useNavigate();
  const handlePaymentRedirect = () => {
    // Implement the redirection to payment logic here
    post("/update/data/",{values:values}).then(res=>{
        if(res.data)
        {
          console.log('Redirecting to payment...');
          post(`/payment/`,{
            values:values
          }).then(res=>{
            console.log(res)
            navigate("/payment",{
              state:{
                form:res.data["form"]
              }
            })
          })
        }
    })

    // For example, window.location.href = '/payment-page';
  };

  return (
    <Card sx={{ maxWidth: 630, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent >
      <Grid item xs={12}>
                  <Button
                    onClick={()=>{
                      Promise.all(refs.map(ref => 
                          ref.current.submitForm()
                      )).then(()=>{
                        if(refs.map(ref=>Object.keys(ref.current.errors).length===0).every(v => v === true))
                        {
                          handlePaymentRedirect();
                        }
                      });
                      //handlePaymentRedirect();
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Forward to Payment
                  </Button>
                </Grid>
      </CardContent>
    </Card>
    
    
  );
};

export default ForwardPaymentButton;
