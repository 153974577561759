import React, { useEffect, useState } from 'react';
import PlanCard from './PlanCard';
import './PricingSection.css';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import '../i18n.js';

function PricingSection() {
  const {get} = useAuth();
  const [Plans,setPlans] = useState([]);
  useEffect(()=>{
      get("/plans/").then(res=>{
        console.log(res.data)
        setPlans(res.data)
      })
  },[get])
  const { t, i18n } = useTranslation();
  return (
    <section className="PricingSection">
      <h2 className="section-title">{t("Our Plans")}</h2>
      <div className="card-container">
        {Plans.map(plan => {
        return (
            <PlanCard 
                  key={plan.id}
                  title={plan.title}
                  description={plan.description}
                  price={plan.price}
                  id={plan.id}
                  PaymentInterval={plan.PaymentInterval}
                />
        )}
        )}
      
      </div>
    </section>
  );
}

export default PricingSection;
