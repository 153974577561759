import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Price = styled.div`
  font-size: 1.5rem;
  color: #007bff;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const ProductDetail = ({title,price,description} ) => {

  // Use provided plan data or fallback to default plan
  console.log(title)
  return (
    <Container>
      <h1>Seçilen Plan</h1>
      <hr style={{width:"100%"}}/>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Price>{price} TL</Price>
    </Container>
  );
};

export default ProductDetail;
