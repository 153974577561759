// BillingInfoCard.js
import React,{useEffect ,forwardRef} from 'react';
import { Formik, Form ,useFormikContext} from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Typography, Card, CardContent, Grid, InputAdornment, MenuItem } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const validationSchema = Yup.object({
  billingAddress: Yup.string().required('Billing Address is required'),
  paymentMethod: Yup.string().required('Payment Method is required'),
  billingContactName: Yup.string().required('Contact Name is required'),
  billingContactEmail: Yup.string()
    .email('Invalid email format')
    .required('Contact Email is required'),
  billingContactPhone: Yup.string().required('Contact Phone is required'),
});

const FormObserver = ({handleOnChange}) => {
    const { values} = useFormikContext();
    useEffect(() => {
        handleOnChange(values);
    }, [values,handleOnChange]);
    return null;
};

const BillingInfoCard = forwardRef(({info,setBillingInfo,submitMode=false},ref) => {
  console.log("bill ",info)
  const initialValues = {
    billingAddress: info?.BillingAddress||"",
    paymentMethod: 'Credit Card',
    billingContactName: info?.BillingContactName||"",
    billingContactEmail: info?.BillingContactEmailAdress||"",
    billingContactPhone: info?.BillingPhoneNumber||"",
  };

  const onSubmit = (values) => {
    console.log('Form data', values);
    // Handle form submission (e.g., send to API)
  };

  const handleOnChange = (event) => {
    console.log("Form::onChange", event);
    setBillingInfo(event);
  };


  return (
    <Card sx={{ maxWidth: 630, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ marginBottom: 2, textAlign: 'center' }}>
          Billing and Payment Information
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={ref}
          enableReinitialize
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormObserver handleOnChange={handleOnChange}/>
              <Grid container spacing={2}>
                {/* Billing Address */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Billing Address"
                    name="billingAddress"
                    value={values.billingAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.billingAddress && Boolean(errors.billingAddress)}
                    helperText={touched.billingAddress && errors.billingAddress}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Payment Method */}
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Payment Method"
                    name="paymentMethod"
                    value={values.paymentMethod}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.paymentMethod && Boolean(errors.paymentMethod)}
                    helperText={touched.paymentMethod && errors.paymentMethod}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PaymentIcon />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Credit Card">Credit Card</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    <MenuItem value="PayPal">PayPal</MenuItem>
                  </TextField>
                </Grid>

                {/* Billing Contact Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Billing Contact Name"
                    name="billingContactName"
                    value={values.billingContactName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.billingContactName && Boolean(errors.billingContactName)}
                    helperText={touched.billingContactName && errors.billingContactName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Billing Contact Email */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Billing Contact Email"
                    name="billingContactEmail"
                    type="email"
                    value={values.billingContactEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.billingContactEmail && Boolean(errors.billingContactEmail)}
                    helperText={touched.billingContactEmail && errors.billingContactEmail}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Billing Contact Phone */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Billing Contact Phone"
                    name="billingContactPhone"
                    value={values.billingContactPhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.billingContactPhone && Boolean(errors.billingContactPhone)}
                    helperText={touched.billingContactPhone && errors.billingContactPhone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Submit Button */}
                {submitMode?
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
                  :<></>}
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
});

export default BillingInfoCard;
