import React from 'react';
import './PlanCard.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.js'; 

function PlanCard({ title, description, price,PaymentInterval,id }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  return (
    <div className="PlanCard">
      <h3 className="plan-title">{title}</h3>
      <p className="plan-description">{description}</p>
      <p className="plan-price">{price} TL / {PaymentInterval}</p>
      <button onClick={()=>user?navigate(`/product/${id}`,{state:{title:title,description:description,price:price}}):navigate("/login")} className="select-plan-button">Plan Seçin</button>
    </div>
  );
}

export default PlanCard;


