import { useParams } from 'react-router-dom';
import ProductDetail from '../components/ProductDetail';
import SubdomainSuggestion from '../components/SubdomainGenerator';
import "./Product.css"
import ForwardPaymentButton from '../components/ForwardPaymentButton';
import Footer from '../navbar/Footer';
import CompanyInfoCard from '../components/CompanyInfoCard';
import BillingInfoCard from '../components/BillingInfoCard';
import OptionalInfoCard from '../components/OptionalInfoCard';
import LegalComplianceCard from '../components/LegalComplianceCard';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../AuthContext';
import { useLocation } from 'react-router-dom';
import StartFreeTrialButton from '../components/StartFreeTrialButton';
function Product(props) {
    const {product_id} = useParams();
    const [CompanyInfo,setCompanyInfo] = useState({});
    const [BillingInfo,setBillingInfo] = useState({});
    const [OptionalInfo,setOptionalInfo] = useState({});
    const [LegalCompliance,setLegalCompliance] = useState({});
    const [SubdomainName,setSubdomainName] = useState("");

    const BillingInfoRef = useRef(null);
    const CompanyInfoRef = useRef(null);
    const LegalComplianceRef = useRef(null);
    const SubdomainRef = useRef(null);
    const {get} = useAuth();
    const [userData,setUserData] = useState({});
    useEffect(()=>{
        console.log("fetching datas")
        get("/user/data/").then(res=>{
            setUserData(res.data)
            console.log("res :",res.data)
        })
    },[get])
    const {state}  = useLocation(); 
    console.log(state)
    return (
    <>
    <div className='main'>
            <ProductDetail {...state}/>
            <SubdomainSuggestion ref={SubdomainRef} setSubdomainName={setSubdomainName}/> 
            <CompanyInfoCard info={userData?.CompanyInfo} ref={CompanyInfoRef} setCompanyInfo={setCompanyInfo}/>
            <BillingInfoCard info={userData?.BillingInfo} ref={BillingInfoRef} setBillingInfo={setBillingInfo}/>
            <OptionalInfoCard setOptionalInfo={setOptionalInfo}/>
            <LegalComplianceCard ref={LegalComplianceRef} setLegalCompliance={setLegalCompliance}/>
            {state.price===0?
            <StartFreeTrialButton refs={[SubdomainRef,CompanyInfoRef,BillingInfoRef,LegalComplianceRef]}  values={{
                CompanyInfo:CompanyInfo,
                BillingInfo:BillingInfo,
                OptionalInfo:OptionalInfo,
                LegalCompliance:LegalCompliance,
                SubdomainName:SubdomainName,
                ProductId:product_id
            }}/>          
            :
            <ForwardPaymentButton refs={[SubdomainRef,CompanyInfoRef,BillingInfoRef,LegalComplianceRef]}  values={{
                CompanyInfo:CompanyInfo,
                BillingInfo:BillingInfo,
                OptionalInfo:OptionalInfo,
                LegalCompliance:LegalCompliance,
                SubdomainName:SubdomainName,
                ProductId:product_id
            }}/>}
    </div>
    <Footer/>
    </>
    );
}

export default Product;