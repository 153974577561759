import React from 'react';
import './AppLinks.css'; // Create a corresponding CSS file for styles
//import googlePlayLogo from './assets/google-play.png'; // Replace with your actual path
//import appStoreLogo from './assets/app-store.png';    // Replace with your actual path
import { useTranslation } from 'react-i18next';


function AppLinks() {
  const { t, i18n } = useTranslation();
  return (
    <div className="AppLinks">
      <h2>{t("Download Our App")}</h2>
      <div className="store-links">
        <a href="https://play.google.com/store/apps/details?id=com.example.app" target="_blank" rel="noopener noreferrer">
          <img src={"https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"} alt="Google Play Store" className="store-logo" />
        </a>
        <a href="https://apps.apple.com/app/id1234567890" target="_blank" rel="noopener noreferrer">
          <img src={"https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"} alt="Apple App Store" className="store-logo" />
        </a>
      </div>
    </div>
  );
}

export default AppLinks;